html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
}

#__next {
  height: 100vh;
}
